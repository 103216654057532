import { USER_ROLE_SUPER_ADMIN, DEFAULT_LOCALE, DB_STUDY_CLOSE_FLAG_VALUE, STUDY_INFORMATION_KEY, DB_STUDY_OPEN_FLAG_VALUE} from "../constants";
import moment from 'moment';
import { CONSTANTS } from 'util/AppConstants';
import intl from "react-intl-universal";

function f(n) {
    return n < 10 ? '0' + n : n;
}

export function currentFullDate() {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;

    var yyyy = today.getFullYear();
    if (dd < 10) {
        dd = '0' + dd;
    }
    if (mm < 10) {
        mm = '0' + mm;
    }
    return yyyy + "" + mm + "" + dd;
}

export function currentDateTime() {
    let d = new Date();
    let date = d.getUTCFullYear() + '-' + f(d.getUTCMonth() + 1) + '-' + f(d.getUTCDate());
    let time = f(d.getUTCHours()) + ":" + f(d.getUTCMinutes()) + ":" + f(d.getUTCSeconds());
    return date + ' ' + time;
}

/**
 * format DEFAULT_DATETIME_FORMAT "yyyy-MM-dd HH:mm:ss";
 */
export function toDateTimeString(datetime) {
    let date = datetime.getFullYear() + '-' + f(datetime.getMonth() + 1) + '-' + f(datetime.getDate());
    let time = f(datetime.getHours()) + ":" + f(datetime.getMinutes()) + ":" + f(datetime.getSeconds());
    return date + ' ' + time;
}


export function currentFullDateString() {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;

    var yyyy = today.getFullYear();
    if (dd < 10) {
        dd = '0' + dd;
    }
    if (mm < 10) {
        mm = '0' + mm;
    }
    return yyyy+'-' + mm +'-'+ dd;
}

export function convertJapanDateTime(dateTimeString, isDay = false) {
    if (!dateTimeString) {
        return "";
    }
    let d = new Date(dateTimeString);
    let date = d.getFullYear() + '年' + f(d.getMonth() + 1) + '月' + f(d.getDate()) + '日';

    if (intl.options.currentLocale !== 'ja-JP') {
        date = d.getFullYear() + '-' + f(d.getMonth() + 1) + '-' + f(d.getDate());
    }

    let time = f(d.getHours()) + ":" + f(d.getMinutes()) + ":" + f(d.getSeconds());
    if (isDay) return date;
    return date + ' ' + time + "（UTC+" + -(d.getTimezoneOffset() / 60) + "）";
}

export function compareTwoDate(firstDate, lastDate) {
    const date1 = new Date(firstDate);
    const date2 = new Date(lastDate);
    const diffTime = Math.abs(date2.getTime() - date1.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return diffDays;
}

export function sortObjectData(data, index, type) {
    let current = data[index];
    let currentSeq = current.seq;
    if (type == "up") {
        for (let i = 1; i < data.length; i++) {
            if (typeof data[index - i] === 'undefined' || data[index - i].deleteFlag) {
                continue;
            }

            let top = data[index - i];
            current.seq = top.seq;
            top.seq = currentSeq;
            data[index - i] = current;
            data[index] = top;
            break;
        }
    }

    if (type == "down") {

        for (let i = 1; i < data.length; i++) {
            if (typeof data[index + i] === 'undefined' || data[index + i].deleteFlag) {
                continue;
            }

            if (typeof data[index + i].newItem !== 'undefined') {
                continue;
            }

            let bottom = data[index + i];
            current.seq = bottom.seq;
            bottom.seq = currentSeq;
            data[index + i] = current;
            data[index] = bottom;
            break;
        }
    }

    return data;
}

export function testValidation(rule, value, callback) {
    console.log('value', value);
    console.log('rule', rule);

    callback();
}

export function checkDuplicatePropertyValueInObjects(propertyName, inputArrayObject) {
    var seenDuplicate = false,
        checkObject = {};

    inputArrayObject.map(function (item) {
        var itemPropertyName = item[propertyName];
        if (itemPropertyName in checkObject) {
            checkObject[itemPropertyName].duplicate = true;
            item.duplicate = true;
            seenDuplicate = true;
        }
        else {
            checkObject[itemPropertyName] = item;
            delete item.duplicate;
        }
    });

    return seenDuplicate;
}

String.prototype.format = function () {
    var formatted = this;
    for (var arg in arguments) {
        formatted = formatted.replace("{" + arg + "}", arguments[arg]);
    }
    return formatted;
};

export function replaceLabel(value, maxLength) {
    //value = toASCII(value);
    //value = value.trim().toUpperCase().replace(/[^A-Z0-9_]/gi, "");
    return value.substring(0, maxLength);
}

export function replaceIdString(value, maxLength = 0) {
    value = toASCII(value);
    value = value.trim().toUpperCase().replace(/[^A-Z0-9_]/gi, "");
    if (maxLength > 0) {
        return value.substring(0, maxLength);
    }
    return value;
}

function toASCII(chars) {
    // console.log(chars);
    return chars.replace(/[Ａ-Ｚａ-ｚ０-９＿]/g, function (s) { return String.fromCharCode(s.charCodeAt(0) - 0xFEE0) });
    // var ascii = '';
    // // console.log(chars);
    // for (var i=0, l=chars.length; i<l; i++) {
    //     var c = chars[i].charCodeAt(0);
    //     if (c >= 0xFF00 && c <= 0xFFEF) {
    //        c = 0xFF & (c + 0x20);
    //     }
    //     ascii += String.fromCharCode(c);
    // }

    // return ascii;
}

export function replaceIdListString(value, maxLength = 0) {
    value = convertFullWidthToHalfWidth(value);
    value = value.toUpperCase().replace(/[^A-Z0-9_,]/gi, "");
    if (maxLength > 0) {
        return value.substring(0, maxLength);
    }
    return value;
}

export function replaceLineListString(value, maxLength = 0) {
    value = convertFullWidthToHalfWidth(value);
    value = value.toUpperCase().replace(/[^0-9,]/gi, "");
    // value = value.replace(/,0/g, ",");
    // if(value.charAt(0) == '0'){
    //     value = value.substr(1);
    // }
    if (maxLength > 0) {
        return value.substring(0, maxLength);
    }
    return value;
}

export function formatOnlyInputInteger(value, maxLength) {
    value = value.replace(/[０-９]/g, function (s) { return String.fromCharCode(s.charCodeAt(0) - 0xFEE0) });
    return value.trim().replace(/[^0-9]|^[0]/gi, "").substring(0, maxLength);
}

export function formatOnlyInputNumeric(value, maxLength) {
    value = value.replace(/[０-９]/g, function (s) { return String.fromCharCode(s.charCodeAt(0) - 0xFEE0) });
    return value.trim().replace(/[^0-9]/gi, "").substring(0, maxLength);
}

export function validateRequired(rule, value, callback) {
    if (value.trim() === "") {
        return callback(this.props.intl.get("MSG-CM-REQUIRED-DATA-INPUT", { item: this.props.intl.get("SB-LABEL-PROPERTY-DATA") }));
    }
    return callback();
}

export function convertFullWidthToHalfWidth(value, maxLength = 0) {
    // convert [\u3000] (Ideographic Space ) to space  [\u0020]
    value = (value || "").replace('　', ' ');
    //convert full-width to half-width: Fullwidth Exclamation Mark ! => Fullwidth Tilde ~    
    var newValue = value.replace(
        /[\uff01-\uff5e]/g,
        function (ch) { return String.fromCharCode(ch.charCodeAt(0) - 0xfee0); }
    );
    if (maxLength > 0) {
        return newValue.substring(0, maxLength);
    }
    return newValue;
}

export function isPresenceOfJapanese(rule, value, callback, message, isDisabled) {
    if (isDisabled) {
        return callback();
    }
    //https://unicode-table.com/en/#cjk-symbols-and-punctuation
    // 3000-303F : punctuation
    // 3040-309F : hiragana
    // 30A0-30FF : katakana
    // FF00-FFEF : Full-width roman + half-width katakana
    // 4E00-9FAF : Common and uncommon kanji
    var regex = /[\u3000-\u303F]|[\u3040-\u309F]|[\u30A0-\u30FF]|[\uFF00-\uFFEF]|[\u4E00-\u9FAF]/g;
    if (regex.test(value)) {
        return callback(message);
    }
    else {
        return callback();
    }
}

export function escapeCharacterMysql(value) {
    // % _
    value = value || "";
    return value.replace(/%/g, "\\%").replace(/_/g, "\\_").trim();
}

export function strLimit(string, length) {
    if (string.length > length) {
        return string.substring(0, length) + "...";
    }

    return string;
}

export function expandPage(e, pageId) {
    document.querySelector(".item-menueditor_" + pageId + " a.link-menueditor").classList.add("active")
}

/**
 * @check superAdmin;
 * @param authorities
 * @returns {boolean}
 */
export function isSuperAdmin(authorities = []) {
    if (typeof authorities != "undefined" && authorities.length > 0 && typeof authorities[0] != "undefined" && authorities[0].authority != "undefined" && authorities[0].authority === USER_ROLE_SUPER_ADMIN ) {
        return true;
    }
    return false;
}

/**
 * Neu user dang login co quyen la superAdmin
 * hoac user dang login vao 1 study ma chi co quyen READ thi de bi disable all input va checkbox select.. from ( su dung cho form antd )
 * @param currentUser
 * @returns {boolean}
 */
export function roleDisable(currentUser, excludeStatusCloseStudy = false) {
    if (currentUser.isSuperAdmin === true || currentUser.privilegeFlag === 0) {
        return true;
    }
    if(excludeStatusCloseStudy) {
        return false;
    }
    let isStudyClose = false;
    const curentStudyId = getStudyIdRequest();
    if (curentStudyId) {
        try {
            const currentStudy = JSON.parse(localStorage.getItem(`currentStudy_${curentStudyId}`));
            if (typeof currentStudy != undefined && currentStudy !=null && currentStudy.closeFlag === DB_STUDY_CLOSE_FLAG_VALUE) {
                isStudyClose = true;
                console.log('curentStudyId ... ', curentStudyId);
            }
        } catch (error) {
            console.log(error);
        }
    }
    return isStudyClose;
}

export function escapeSearchStr(string = "") {
    string = string || "";
    let newString = escapeStr(string.trim());
    return newString;
}
export function escapeStr(string) {
    return ('' + string).replace(/["'\\\n\r\u2028\u2029\t%_]/g, function (character) {
      // Escape all characters not included in SingleStringCharacters and
      // DoubleStringCharacters on
      // http://www.ecma-international.org/ecma-262/5.1/#sec-7.8.4
      switch (character) {
        case '"':
        case "'":
        case '\\':
            return '\\' + character
        // Four possible LineTerminator characters need to be escaped:
        case '\n':
            return '\\n'
        case '\r':
            return '\\r'
        case '\t':
            return '\\t'
        case '\u2028':
            return '\\u2028'
        case '\u2029':
            return '\\u2029'
        case '%':
            return "\\%"
        case '_':
            return "\\_"
        default:
            return character
      }
    })
}

export function getDefaultValueDate(date, layout) {
    let DATE_FORMAT = ['YYYY-MM-DD'];
    switch (layout) {
        case CONSTANTS.DB_ITEM_LAYOUT_DATE_YYYY_MM_UN:
            DATE_FORMAT.push('YYYY-MM-UN');
            break;
        case CONSTANTS.DB_ITEM_LAYOUT_DATE_YYYY_UN_UN:
            DATE_FORMAT.push('YYYY-MM-UN', 'YYYY-UN-UN', 'YYYY-UN-DD');
            break;
        case CONSTANTS.DB_ITEM_LAYOUT_DATE_UNUN_UN_UN:
            DATE_FORMAT.push('YYYY-MM-UN', 'YYYY-UN-UN', 'YYYY-MM-UN', 'YYYY-UN-DD', 'UNUN-UN-UN', 'UNUN-UN-DD', 'UNUN-MM-UN', 'UNUN-MM-DD');
            break;
        default:
            break;
    }
    date = date.replace('年', '-');
    date = date.replace('月', '-');
    date = date.replace('日', '');
    date = date.toUpperCase();
    date = getDateStrPad(date);

    const checkDateByFormats = DATE_FORMAT.map(format => moment(date, format, true).isValid() || date === format);
	let isDateValid = Boolean(checkDateByFormats.find(Boolean));
	const yearFromValue = parseInt(date.slice(0,4));
    
    if (yearFromValue < 1900 && isDateValid == true) {
        isDateValid = false;
    }

    if (isDateValid === false) {
        return "";
    } else {
        return date;
    }
}
export function getDefaultValueTime(time, layout) {
	if (!time || time.length != 5) {
		return "";
	}
	let res = time.split(":");
	if (res.length !== 2) {
		return "";
	}

	let hours = getTimeByString(res[0], "hours");
	let min = getTimeByString(res[1], "min");
	if (!hours || !min) {
		return "";
	}
	if (layout === CONSTANTS.DB_ITEM_LAYOUT_TIME_HH_MM && (hours === "UN" || min === "UN")) {
		return "";
	}
	if (layout === CONSTANTS.DB_ITEM_LAYOUT_TIME_HH_UN && hours === "UN") {
		return "";
	}
	return hours + ":" + min;
}
function getTimeByString(string, type) {
	if (!string) {
		return "";
	}
	if (string.match(/^UN/i)) {
		return "UN";
	}
	let j = 23;
	if (type === "min") {
		j = 59;
	}
	for (let i = 0; i <= j; i++) {
		let h = String("00" + i).slice(-2);
		if (h === string) {
			return h;
		}
	}
	return "";
}
export function getDateStrPad(date) {
	let arrayDate = date.split("-");
	if (arrayDate.length !== 3) {
		return date;
	}
	let year = arrayDate[0];
	let month = arrayDate[1].match(/^UN/i) ? arrayDate[1] : String("00" + arrayDate[1]).slice(-2);
	let day = arrayDate[2].match(/^UN/i) ? arrayDate[2] : String("00" + arrayDate[2]).slice(-2);

	return year + "-" + month + "-" + day;
}

export function UtcTimeList() {
  const UtcTimeList = [
    { time: 12, UTC: "UTC+12 -" },
    { time: 11, UTC: "UTC+11 -" },
    { time: 10, UTC: "UTC+10 Canberra, Melbourne, Sydney" },
    { time: 9, UTC: "UTC+9 Seoul, Tokyo" },
    { time: 8, UTC: "UTC+8 Baijing, Hongkong, Kuala Lumpur, Manila, Singapore, Taibei",},
    { time: 7, UTC: "UTC+7 Bangkok, Hanoi, Jakarta" },
    { time: 6, UTC: "UTC+6 -" },
    { time: 5, UTC: "UTC+5 -" },
    { time: 4, UTC: "UTC+4 -" },
    { time: 3, UTC: "UTC+3 Moscow" },
    { time: 2, UTC: "UTC+2 Bucharest, Kiev, Sophia" },
    { time: 1, UTC: "UTC+1 Berlin, Madrid, Paris, Praha, Rome, Warsaw" },
    { time: 0, UTC: "UTC+0 Lisbon, London" },
    { time: -1, UTC: "UTC-1 -" },
    { time: -2, UTC: "UTC-2 -" },
    { time: -3, UTC: "UTC-3 -" },
    { time: -4, UTC: "UTC-4 -" },
    { time: -5, UTC: "UTC-5 Eastern Standard Time" },
    { time: -6, UTC: "UTC-6 Central Standard Time" },
    { time: -7, UTC: "UTC-7 Mountain Standard Time" },
    { time: -8, UTC: "UTC-8 Western Standard Time" },
    { time: -9, UTC: "UTC-9 -" },
    { time: -10, UTC: "UTC-10 -" },
    { time: -11, UTC: "UTC-11 -" },
  ];
  return UtcTimeList;
}

export function getLocalUtc(isNumber = true) {
	let offset = new Date().getTimezoneOffset(),
		o = Math.abs(offset);
	if (typeof isNumber !== "undefined" && isNumber === true) {
		return offset < 0 ? (o / 60) : (o / 60)*(-1)
	}

	return (offset < 0 ? "+" : "-") + (Math.floor(o / 60)); //.slice(-2);
}

export function convertDateTimeToTimeZone(stringDatetime = null, timezone) {
	let utc = getLocalUtc();
	if (stringDatetime) {
        let utcTime = new Date(stringDatetime)
        let utcString = " (UTC" + (utc >= 0 ? "+" : "-") + utc.toString() + ")";

        utcTime.setHours(utcTime.getHours() + utc );
		return toDateTimeString(utcTime) + utcString;
	}
	return null
}

export function resetCssHeightTrTagElement(tbody) {
    // Bug_Ominext #45711: Lỗi UI khi set exclude page
    // height of this.tbody.current, including padding and border:
    let tbodyHeight = tbody.offsetHeight;
    let totalHeight = 0;
    var rowsList = tbody.rows;
    for (let i = 1; i < rowsList.length; i++) {
        rowsList[i].style.height = rowsList[i].offsetHeight + "px";
        totalHeight = totalHeight + rowsList[i].offsetHeight;
    }
    let addHeight = tbodyHeight - totalHeight;
    rowsList[0].style.height = addHeight + "px";
}

export function forceRefreshComponent(props, componentUrl){
    props.history.push("/force-refresh");

    setTimeout(() => {
        props.history.replace(componentUrl);
    });
}

export function stringByteLength(str) {
    // returns the byte length of an utf8 string
    var s = str.length;
    for (var i=str.length-1; i>=0; i--) {
      var code = str.charCodeAt(i);
      if (code > 0x7f && code <= 0x7ff) s++;
      else if (code > 0x7ff && code <= 0xffff) s+=2;
      if (code >= 0xDC00 && code <= 0xDFFF) i--; //trail surrogate
    }
    return s;
}

export function checkIfStringHaveUpperCaseLetter(text) {
    let upperCase = new RegExp("[A-Z]");
    return upperCase.test(text);
}

export function regExpDecimalNummeric(naturalPartMaxLength, decimalPartMaxLength) {
    let regNumMaxLength = RegExp(`^[1-9][0-9]{0,${naturalPartMaxLength - 1}}$`);
    if (decimalPartMaxLength == 1) {
        regNumMaxLength = RegExp(`^[1-9][0-9]{0,${naturalPartMaxLength - 1}}(\\.[1-9]{0,1})?$`);
    } else if (decimalPartMaxLength > 1) {
        regNumMaxLength = RegExp(`^[1-9][0-9]{0,${naturalPartMaxLength - 1}}(\\.[1-9][0-9]{0,${decimalPartMaxLength - 1}})?$`);
    }
    return regNumMaxLength;
}

export function valueFromEventHandle(value, naturalPartMaxLength = 4, decimalPartMaxLength = 0) {
    console.log("naturalPartMaxLength",naturalPartMaxLength);
    console.log("decimalPartMaxLength",decimalPartMaxLength);

    value = (value || "").trim();
    // convert số full-width thành số half-width
    value = value.replace(/[０-９]/g, function (s) { return String.fromCharCode(s.charCodeAt(0) - 0xFEE0) });
    // convert dấu [.] full-width thành half-width : ｡．。. 0xff61, 0xff0e, 0x3002, 0x2e
    value = value.replace(/[\uff61|\uff0e|\u3002]/g, function (s) { return "." });

    let regNumMaxLength = regExpDecimalNummeric(naturalPartMaxLength, decimalPartMaxLength);
    if ((!isNaN(value) && regNumMaxLength.test(value)) || value === '') {
        return value;
    }
    let valueTemp = value;
    // kiểm tra số được nhập là số nguyên hay số thập phân
    if (decimalPartMaxLength == 0) {
        valueTemp = valueTemp.replace(/[^0-9]|^[0]/gi, "");
        return valueTemp.substring(0, naturalPartMaxLength);
    } else {
        // thay thế các ký tự ko phải số hoặc dấu [.], hoặc số 0 ở đầu
        valueTemp = valueTemp.replace(/[^0-9.]|^[0]|^[.]/gi, "");
    }
    //phần nguyên 
    let naturalPart = valueTemp.split(".")[0];
    let haveDot = valueTemp.includes(".");
    if (naturalPart.length > 0) {
        // loại số 0 ở đầu
        let index = 0
        let indexRemove = 0
        for (index = 0; index < naturalPart.length; index++) {
            if (naturalPart.charAt(index) === '0') {
                indexRemove = index + 1;
            } else {
                break;
            }
        }
        naturalPart = naturalPart.slice(indexRemove);
    }
    // ngắt
    if (naturalPart.length > naturalPartMaxLength) {
        naturalPart = naturalPart.substring(0, naturalPartMaxLength);
    }
    // nhập "." => convert thành "1."
    if (naturalPart.length == 0 && haveDot) {
        naturalPart = "1";
    }
    // phần thập phân
    let decimalPart = valueTemp.slice(valueTemp.split(".")[0].length);
    console.log("valueFromEventHandle decimalPart", decimalPart);
    decimalPart = decimalPart.includes(".") ? decimalPart.replace(/\./g, "") : decimalPart;
    // loại số 0 ở đầu
    if (decimalPart.length > 0) {
        let index = 0
        let indexRemove = 0
        for (index = 0; index < decimalPart.length; index++) {
            if (decimalPart.charAt(index) === '0') {
                indexRemove = index + 1;
            } else {
                break;
            }
        }
        decimalPart = decimalPart.slice(indexRemove);
    }
    // ngắt
    if (decimalPart.length > decimalPartMaxLength) {
        decimalPart = decimalPart.substring(0, decimalPartMaxLength);
    }
    if (haveDot) {
        return `${naturalPart}.${decimalPart}`;
    }
    else {
        return naturalPart;
    }
}
export function initIntl(ja, eng, intl) {
    console.log('----------', ja, eng, intl)
    let currentLocale = (localStorage.getItem('selectedLocale')) ? localStorage.getItem('selectedLocale') : DEFAULT_LOCALE;
    if (currentLocale === 'ja-JP') {
        intl.load({ [currentLocale]: ja });
    } else {
        intl.load({ [currentLocale]: eng });
    }
}

/**
 * get id of current study
 *
 * @returns 
 */
export function getStudyIdRequest() {
    let studyInUrl = 0;
    let currentUrl = window.location.href;
    // console
    if (currentUrl.includes('/study/')) {
        let studyMatch = currentUrl.match(/\/study\/[1-9][0-9]*/g);
        if (studyMatch) {
            studyInUrl = parseInt(studyMatch[0].replaceAll(/[^\d]/g, ''));
        }
    }
    return studyInUrl;
}

/**
 * get close_flag of current study
 *
 * @param {*} studyId 
 * @returns 
 */
export function getStudyCloseFlag (studyId) {
    let closeFlag = DB_STUDY_OPEN_FLAG_VALUE;
    try {
        const studyInfor = JSON.parse(localStorage.getItem(`${STUDY_INFORMATION_KEY}${studyId}`));
        if (typeof studyInfor!= 'undefined' && studyInfor != null && studyInfor.closeFlag != 'undefined') {
            closeFlag = studyInfor.closeFlag;
        }
    } catch (error) {
        console.log ("getStudyCloseFlag >>> error: ", error);
    }
    return closeFlag;
}