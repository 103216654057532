const JP_ITEM_MESSAGE_CODE = {
    null: "",
    "": "",
    "TYPE.ERROR": "エラー",
    "SM210MSG003":"整数値を入力してください。",
    "SM910MSG004": "ARMは100文字以下で設定してください。",

    // BUTTON
    "CM-BUTTON-ADD": "追加",
    "CM-BUTTON-CREATE": "新規作成",
    "CM-BUTTON-REGISTER": "登録",
    "CM-BUTTON-CLEAR-ALL": "条件クリア",
    "CM-BUTTON-SEARCH": "検索",
    "CM-BUTTON-SEND": "送信",
    "CM-BUTTON-YES": "はい",
    "CM-BUTTON-NO": "いいえ",
    "CM-BUTTON-IMPORT": "取り込み",
    "CM-BUTTON-UPLOAD": "アップロード",
    "CM-BUTTON-DOWNLOAD": "ダウンロード",
    "CM-BUTTON-SELECT": "選択",
    "CM-BUTTON-SAVE": "保存",
    "CM-BUTTON-CANCEL": "キャンセル",
    "CM-BUTTON-CLOSE": "閉じる",
    "CM-BUTTON-AUDIT-TRAIL": "Audit Trail",
    "CM-BUTTON-BACK-TO-LOGIN": "ログインに戻る",
    "CM-BUTTON-LOGOUT": "ログアウト",
    "CM-BUTTON-LOGIN": "ログイン",
    "CM-BUTTON-PASSWORD-INITIALIZATION": "パスワード初期化",
    "CM-BUTTON-DELETE": "削除",
    "CM-BUTTON-RESTORE": "回復",
    "SB-BUTTON-OPEN-STUDY": "オープン",
    "SB-BUTTON-CLOSE-STUDY": "クローズ",
    "SB-BUTTON-ADD-BLOCK": "ブロック追加",
    "SB-BUTTON-ADD-QUESTION": "質問追加",
    "SB-BUTTON-ADD-ITEM": "項目追加",
    "SB-BUTTON-PREVIEW": "プレビュー",
    "SB-BUTTON-CRF-PAGE": "CRFページ",
    "SB-BUTTON-PROPERY": "Property",
    "SB-BUTTON-CLONE-STUDY": "CLONE",
    "SB-BUTTON-RELEASE-STUDY": "RELEASE",

    // LABEL
    "CM-LABEL-TODAY": "本日",
    "CM-LABEL-REQUIRED": "必須",
    "CM-LABEL-REQUIRED-FIELD": "※は必須項目です。",
    "CM-LABEL-PER-PAGE": "件表示",
    "CM-LABEL-DISPLAY-RECORD-FROM-TO": "{z}件中{x}から{y}まで表示",
    "CM-LABEL-FIRST-PAGE": "最初",
    "CM-LABEL-LAST-PAGE": "最後",
    "CM-LABEL-PREVIOUS-PAGE": "前",
    "CM-LABEL-NEXT-PAGE": "次",
    "SB-LABEL-UPDATED-USER": "更新者",
    "SB-LABEL-UPDATED-TIME": "更新日時",
    "CM-LABEL-AUDIT": "Audit",
    "CM-LABEL-AUDIT-TRAIL": "Audit Trail",
    "CM-LABEL-AUDIT-TABLE-TITLE": "{item}の編集履歴",
    "CM-LABEL-ORDINAL-NUMBER": "No.",
    "SB-LABEL-FILE": "ファイル",
    "CM-LABEL-STATUS-01": "ステータス",
    "SB-LABEL-ACTION": "操作",
    "CM-LABEL-DELETE": "削除",
    "CM-LABEL-LABEL": "ラベル",
    "CM-LABEL-CODE": "コード",
    "CM-LABEL-MINUTE-UNIT": "Minutes",
    "CM-LABEL-VERSION": "バージョン",
    "SB-LABEL-VERSION": "Version",
    "SB-LABEL-ID": "ID",
    "SB-LABEL-VISIT-ID": "Visit ID",
    "SB-LABEL-PAGE-ID": "Page ID",
    "SB-LABEL-BLOCK-ID": "Block ID",
    "SB-LABEL-ITEM-ID": "Item ID",
    "CM-LABEL-RESET-PWD-INITIALIZATION": "パスワード初期化",
    "CM-LABEL-CONFIRMATION": "確認",
    "CM-LABEL-EMAIL-02": "メール",
    "CM-LABEL-EXCEL-UPLOAD-MODAL": "Excelファイルの取り込み",
    "CM-LABEL-USER-NAME": "氏名",
    "CM-LABEL-ERROR": "エラー",
    "CM-LABEL-ALERT-MESSAGE": "メッセージ",

    // Authen
    "SB-LABEL-LOGIN": "ログイン",
    "CM-LABEL-LOGIN-ID": "ユーザーID",
    "CM-LABEL-PASSWORD": "パスワード",
    "CM-LABEL-FORGOT-PASSWORD": "パスワードを忘れた場合",
    "CM-LABEL-FORGOT-USER-ID": "ユーザーIDを忘れた場合",
    "SB-LABEL-PASSWORD-SETTING": "パスワード設定",
    "CM-LABEL-EMAIL-01": "メールアドレス",
    "CM-LABEL-SECURITY-QUESTION": "秘密の質問",
    "CM-LABEL-SECURITY-ANSWER-01": "回答",
    "CM-LABEL-NEW-PASSWORD": "新しいパスワード",
    "CM-LABEL-CONFIRM-NEW-PASSWORD": "新しいパスワードの確認",

    // Study
    "CM-LABEL-STUDY-LIST": "試験一覧",
    "SB-LABEL-STUDY-DETAIL": "試験の基本情報更新",
    "SB-LABEL-CREATE-STUDY": "試験を新規作成",
    "CM-LABEL-STUDY-NAME": "試験名",
    "CM-LABEL-PROTOCOL-NO": "プロトコルNo.",
    "CM-LABEL-ALIAS": "Alias",
    "CM-LABEL-STUDY-ID": "試験ID",
    "CM-LABEL-SYSTEM-LINK": "システム連携",
    "SB-LABEL-STUDY": "試験",
    "CM-LABEL-OPEN": "オープン",
    "CM-LABEL-CLOSE": "クローズ",
    "SB-LABEL-CDMS": "CDMS",
    "SB-LABEL-PRO": "PRO",
    "SB-LABEL-ECONSENT": "eConsent",
    "CM-LABEL-IWRS": "IWRS",
    "SB-LABEL-STUDY-MANAGEMENT-SYSTEM": "試験管理システム",

    // EDC Setting
    "SB-LABEL-EDC-SETTING": "EDC設定",
    "CM-LABEL-ETRAINING": "e-Training",
    "SB-LABEL-DIGITS-PER-SITE-CODE": "Digits per site code",
    "SB-LABEL-SCREENING-NUMBER": "Screening number",
    "SB-LABEL-ENROLLMENT-NUMBER": "Enrollment number",
    "SB-LABEL-LEVELS-CONTRACT-CASES":
        "Set maximum limit for the number of registered subjects",
    "SB-LABEL-AUTOMATIC-LOGOUT-TIME": "Automatic logout time",
    "SB-LABEL-VALUE": "Value",

    // E-Training
    "SB-LABEL-DOCUMENT-UPLOAD-MODAL": "資料のアップロード",
    "CM-LABEL-DOCUMENT-NAME": "資料名称 ",
    "SB-LABEL-DOCUMENT-DESCRIPTION": "資料の説明",
    "SB-LABEL-UPDATED-DATE": "更新日",
    "SB-LABEL-DOWNLOAD": "ダウンロード",
    "SB-LABEL-DOCUMENT": "資料",

    // Role & priviledge
    "SB-LABEL-ROLE-PRIVILEGE": "権限設定",
    "CM-LABEL-ROLE": "ロール",
    "CM-LABEL-PRIVILEGE": "権限",
    "CM-LABEL-SETTING": "設定",
    "SB-LABEL-ROLE-LIST": "ロール一覧",
    "SB-LABEL-PRIVILEGE-LIST": "権限一覧",
    "SB-LABEL-ENABLE": "有効",
    "SB-LABEL-DESCRIPTION": "説明",
    "SB-LABEL-CATEGORY": "カテゴリー",
    "CM-LABEL-DETAIL": "詳細",
    "SB-LABEL-PRIVILEGE-CRF-VIEW": "CRF_VIEW",
    "SB-LABEL-PRIVILEGE-CRF-EDIT": "CRF_EDIT",
    "SB-LABEL-PRIVILEGE-FREEZE-VIEW": "FREEZE_VIEW",
    "SB-LABEL-PRIVILEGE-FREEZE-EDIT": "FREEZE_EDIT",
    "SB-LABEL-PRIVILEGE-SDV-VIEW": "SDV_VIEW",
    "SB-LABEL-PRIVILEGE-SDV-EDIT": "SDV_EDIT",
    "SB-LABEL-PRIVILEGE-REVIEW-VIEW": "REVIEW_VIEW",
    "SB-LABEL-PRIVILEGE-REVIEW-EDIT": "REVIEW_EDIT",
    "SB-LABEL-PRIVILEGE-LOCK-VIEW": "LOCK_VIEW",
    "SB-LABEL-PRIVILEGE-LOCK-EDIT": "LOCK_EDIT",
    "SB-LABEL-PRIVILEGE-DB-LOCK-VIEW": "DB_LOCK_VIEW",
    "SB-LABEL-PRIVILEGE-DB-LOCK-EDIT": "DB_LOCK_EDIT",
    "SB-LABEL-PRIVILEGE-SIGNATURE-VIEW": "SIGNATURE_VIEW",
    "SB-LABEL-PRIVILEGE-SIGNATURE-EDIT": "SIGNATURE_EDIT",
    "SB-LABEL-PRIVILEGE-ECS-RE-EXECUTE": "ECS_RE_EXECUTE",
    "SB-LABEL-PRIVILEGE-CASE-REGISTER": "CASE_REGISTER",
    "SB-LABEL-PRIVILEGE-CASE-DELETE": "CASE_DELETE",
    "SB-LABEL-PRIVILEGE-PAGE-INITIAL": "PAGE_INITIAL",
    "SB-LABEL-PRIVILEGE-ADMIN-VIEW": "ADMIN_VIEW",
    "SB-LABEL-PRIVILEGE-ADMIN-EDIT": "ADMIN_EDIT",
    "SB-LABEL-PRIVILEGE-QUERY-ISSUE": "QUERY_ISSUE",
    "SB-LABEL-PRIVILEGE-QUERY-VIEW": "QUERY_VIEW",
    "SB-LABEL-PRIVILEGE-QUERY-ANSWER": "QUERY_ANSWER",
    "SB-LABEL-PRIVILEGE-COMMENT-ADD": "COMMENT_ADD",
    "SB-LABEL-PRIVILEGE-DATASET-EXPORT": "DATASET_EXPORT",
    "SB-LABEL-PRIVILEGE-ASSIGNMENT-IMPORT": "ASSIGNMENT_IMPORT",
    "SB-LABEL-PRIVILEGE-ASSIGNMENT-VIEW": "ASSIGNMENT_VIEW",
    "SB-LABEL-PRIVILEGE-USER-REPORT-EXPORT": "USER_REPORT_EXPORT",
    "SB-LABEL-PRIVILEGE-USER-REPORT-EDIT": "USER_REPORT_EDIT",
    "SB-LABEL-PRIVILEGE-IP-NO-DELIVERY": "IP_NO_DELIVERY",
    "SB-LABEL-PRIVILEGE-LIST-UPLOAD": "LIST_UPLOAD",
    "SB-LABEL-PRIVILEGE-EMERGENCY-KEY-OPEN": "EMERGENCY_KEY_OPEN",
    "SB-LABEL-PRIVILEGE-EMERGENCY-KEY-APPROVE": "EMERGENCY_KEY_APPROVE",
    "SB-LABEL-PRIVILEGE-CRF-IMPORT-DATA": "CRF_IMPORT_DATA",
    "SB-LABEL-PRIVILEGE-LINK-ECONSENT": "LINK_ECONSENT",
    "SB-LABEL-PRIVILEGE-ECONSENT-HISTORY-VIEW": "ECONSENT_HISTORY_VIEW",
    "SB-LABEL-PRIVILEGE-ETRAINING-CERT-DOWNLOAD": "ETRAINING_CERT_DOWNLOAD",
    "SB-LABEL-PRIVILEGE-ACTIVE_EMERGENCY": "ACTIVE_EMERGENCY",
    "SB-LABEL-PRIVILEGE-EMERGENCY-HISTORY-VIEW": "EMERGENCY_HISTORY_VIEW",
    "SB-LABEL-CATEGORY-CRF": "CRF",
    "SB-LABEL-CATEGORY-FREEZE": "FREEZE",
    "SB-LABEL-CATEGORY-SDV": "SDV",
    "SB-LABEL-CATEGORY-REVIEW": "REVIEW",
    "SB-LABEL-CATEGORY-LOCK": "LOCK",
    "SB-LABEL-CATEGORY-DB-LOCK": "DB_LOCK",
    "SB-LABEL-CATEGORY-SIGNATURE": "SIGNATURE",
    "SB-LABEL-CATEGORY-ECS-RE": "ECS_RE",
    "SB-LABEL-CATEGORY-CASE": "CASE",
    "SB-LABEL-CATEGORY-PAGE": "PAGE",
    "SB-LABEL-CATEGORY-ADMIN": "ADMIN",
    "SB-LABEL-CATEGORY-QUERY": "QUERY",
    "SB-LABEL-CATEGORY-COMMENT": "COMMENT",
    "SB-LABEL-CATEGORY-DATASET": "DATASET",
    "SB-LABEL-CATEGORY-ASSIGNMENT": "ASSIGNMENT",
    "SB-LABEL-CATEGORY-USER-REPORT": "USER_REPORT",
    "SB-LABEL-CATEGORY-DELIVERY": "DELIVERY",
    "SB-LABEL-CATEGORY-LIST-UPLOAD": "LIST_UPLOAD",
    "SB-LABEL-CATEGORY-BLIND": "BLIND",
    "SB-LABEL-CATEGORY-DATA-IMPORT": "DATA_IMPORT",
    "SB-LABEL-CATEGORY-ECONSENT": "ECONSENT",
    "SB-LABEL-CATEGORY-E-TRAINING": "E-TRAINING",
    "SB-LABEL-DESCRIPTION-CRF-VIEW":
        "CRFにアクセスでき、入力データの閲覧ができる",
    "SB-LABEL-DESCRIPTION-CRF-EDIT":
        "CRFにアクセスでき、データを入力することができる",
    "SB-LABEL-DESCRIPTION-FREEZE-VIEW":
        "Freezeされている箇所についてFreezeフラグの閲覧ができる",
    "SB-LABEL-DESCRIPTION-FREEZE-EDIT":
        "質問毎にFreezeフラグを立てることができる",
    "SB-LABEL-DESCRIPTION-SDV-VIEW":
        "SDVされている箇所についてSDVフラグの閲覧ができる",
    "SB-LABEL-DESCRIPTION-SDV-EDIT": "質問毎にSDVフラグを立てることができる",
    "SB-LABEL-DESCRIPTION-REVIEW-VIEW":
        "レビューされている箇所についてレビューフラグの閲覧ができる",
    "SB-LABEL-DESCRIPTION-REVIEW-EDIT":
        "質問毎にレビューフラグを立てることができる",
    "SB-LABEL-DESCRIPTION-LOCK-VIEW":
        "ロックされている箇所についてロックフラグの閲覧ができる",
    "SB-LABEL-DESCRIPTION-LOCK-EDIT":
        "質問毎にロックフラグを立てることができる",
    "SB-LABEL-DESCRIPTION-DB-LOCK-VIEW":
        "DBロックされている箇所についてDBロックフラグの閲覧ができる",
    "SB-LABEL-DESCRIPTION-DB-LOCK-EDIT": "DBロックを実施することができる",
    "SB-LABEL-DESCRIPTION-SIGNATURE-VIEW": "署名ページについて閲覧ができる",
    "SB-LABEL-DESCRIPTION-SIGNATURE-EDIT":
        "署名ページについてデータの入力ができる",
    "SB-LABEL-DESCRIPTION-ECS-RE-EXECUTE": "Editcheckを再実行することができる",
    "SB-LABEL-DESCRIPTION-CASE-REGISTER": "症例の追加できる",
    "SB-LABEL-DESCRIPTION-CASE-DELETE": "被験者を削除することができる",
    "SB-LABEL-DESCRIPTION-PAGE-INITIAL": "ページ単位で初期化することができる",
    "SB-LABEL-DESCRIPTION-ADMIN-VIEW":
        "アカウント管理のページを閲覧することができる",
    "SB-LABEL-DESCRIPTION-ADMIN-EDIT":
        "アカウント管理ページにてアカウントの追加、役割の設定をすることができる",
    "SB-LABEL-DESCRIPTION-QUERY-ISSUE":
        "クエリを発行、キャンセル、クローズすることができる",
    "SB-LABEL-DESCRIPTION-QUERY-VIEW":
        "クエリが発出している箇所について閲覧することができる",
    "SB-LABEL-DESCRIPTION-QUERY-ANSWER":
        "発出されているクエリについて回答することができる",
    "SB-LABEL-DESCRIPTION-COMMENT-ADD":
        "コメントの各項目に追加することができる",
    "SB-LABEL-DESCRIPTION-DATASET-EXPORT":
        "入力されたデータをDatasetとして出力することができる",
    "SB-LABEL-DESCRIPTION-ASSIGNMENT-IMPORT":
        "割付のリストをデータベースにインポートすることができる",
    "SB-LABEL-DESCRIPTION-ASSIGNMENT-VIEW":
        "インポートした割付リストについて閲覧することができる",
    "SB-LABEL-DESCRIPTION-USER-REPORT-EXPORT": "帳票を出力することができる",
    "SB-LABEL-DESCRIPTION-USER-REPORT-EDIT":
        "出力する帳票を設定することができる",
    "SB-LABEL-DESCRIPTION-IP-NO-DELIVERY": "配送設定を行う権限",
    "SB-LABEL-DESCRIPTION-LIST-UPLOAD": "薬剤リストをアップロードする権限",
    "SB-LABEL-DESCRIPTION-EMERGENCY-KEY-OPEN":
        "エマージェンシーキーオープンを申請する権限",
    "SB-LABEL-DESCRIPTION-EMERGENCY-KEY-APPROVE":
        "エマージェンシーキーオープンを承認する権限",
    "SB-LABEL-DESCRIPTION-CRF-IMPORT-DATA": "CRFページをインポートする権限",
    "SB-LABEL-DESCRIPTION-LINK-ECONSENT": "eConsentへ接続する権限",
    "SB-LABEL-DESCRIPTION-ECONSENT-HISTORY-VIEW": "eConsent履歴閲覧権限",
    "SB-LABEL-DESCRIPTION-ETRAINING-CERT-DOWNLOAD": "証明書ダウンロード権限",
    "SB-LABEL-DESCRIPTION-ACTIVE-EMERGENCY": "緊急時だけできる権限",
    "SB-LABEL-DESCRIPTION-EMERGENCY-HISTORY-VIEW": "緊急時履歴閲覧権限",

    // Cycle&Visit
    "SB-LABEL-ENTRY": "Entry",
    "SB-LABEL-CYCLE-AND-VISIT": "Cycle&Visit",
    "SB-LABEL-CYCLE-ID": "Cycle ID",
    "SB-LABEL-REPEAT": "繰返し",
    "SB-LABEL-CYCLE-CODE":"Cycleコード",
    "SB-LABEL-CYCLE":"Cycle",
    "SB-LABEL-VISIT-CODE":"Visitコード",
    "SB-LABEL-VISIT":"Visit",
    "SB-LABEL-VISIBLE": "表示",

    // Schedule
    "SB-LABEL-SCHEDULE": "スケジュール",
    "SB-LABEL-DIARY-SETTING": "Diary設定",
    "SB-LABEL-DIARY-START-FROM": "Start diary from",
    "SB-LABEL-DIARY-DELAY": "Delay",
    "SB-LABEL-DIARY-INPUT-PERIOD": "入力期間",
    "SB-LABEL-DIARY-INPUT-INTERVAL": "入力間隔",
    "SB-LABEL-DIARY-NUMBER-OF-ENTRY-PER-DAY": "1日入力回数",
    "SB-LABEL-DIARY-ENTRY-DEADLINE": "入力期限",
    "SB-LABEL-DIARY-NOTIFICATION": "Notification",
    "SB-LABEL-DIARY-NOTIFICATION-DELIVERY-TIME": "配信時間",
    "SB-LABEL-DIARY-DATA-ENTRY-NOTIFICATION": "eDiaryのエントリー時刻",
    "SB-LABEL-DIARY-ENTRY-EXPIRY-NOTIFICATION": "eDiary入力期限が切れた通知",
    "SB-LABEL-DIARY-SNOOZE": "スヌーズ",
    "SB-LABEL-DIARY-REPEAT": "繰り返す",
    "SB-LABEL-DIARY-NOTIFICATION-INTERVAL": "間隔",
    "SB-LABEL-DIARY-HOUR-UNIT": "時",
    "SB-LABEL-DIARY-TIMES-UNIT": "回",
    "SB-LABEL-DIARY-VISIT-DATE": "visit date",
    "SB-LABEL-DIARY-DAY-UNIT": "day(s)",

    // CRF Group
    "SB-LABEL-CRF-GROUP": "CRFグループ",
    "SB-LABEL-CRF-GROUP-ID": "グループID",
    "SB-LABEL-CRF-GROUP-TYPE": "種別",
    "SB-LABEL-SEQ-NO": "Seq No.",

    // CRF page
    "SB-LABEL-CRF-PAGE": "CRFページ",
    "SB-LABEL-CRF-PAGE-EDITOR": "ページ編集 ({group_id_text} - {crf_version})",
    "CM-LABEL-PAGE": "ページ",
    "SB-LABEL-CRF-GROUP-NAME": "CRF グループ名",
    "SB-LABEL-NUMBER-OF-LINE": "行数",
    "SB-LABEL-CREATE-BLOCK": "ブロック作成",
    "SB-LABEL-EDIT-BLOCK": "ブロック編集 ({block_id_text} - {crf_version})",
    "SB-LABEL-CREATE-QUESTION": "質問作成",
    "SB-LABEL-EDIT-QUESTION": "質問編集 ({question_id_text} - {crf_version})",
    "SB-LABEL-CREATE-ITEM": "項目作成",
    "SB-LABEL-EDIT-ITEM": "項目編集 ({item_id_text} - {crf_version})",
    "SB-LABEL-DOMAIN-ID": "Domain ID",
    "SB-LABEL-ITEM-LAYOUT": "レイアウト",
    "SB-LABEL-ITEM-CODELIST": "CodeList",
    "SB-LABEL-ITEM-DATA-TYPE": "データタイプ",
    "SB-LABEL-ITEM-DATA-LENGTH": "データの長さ",
    "SB-LABEL-ITEM-REQUIRED-SETTING": "必須項目",
    "SB-LABEL-ITEM-MAX-VALUE": "最大値",
    "SB-LABEL-ITEM-MIN-VALUE": "最小値",
    "SB-LABEL-CRF-PAGE-PREVIEW": "プレビュー",
    "SB-LABEL-CRF-PAGE-CUSTOMIZATION-GUIDE":
        "※プレビュー画面を変更した後は必ずページを保存してください。",

    // Property
    "SB-LABEL-PROPERTY": "PROPERTY",
    "SB-LABEL-PROPERTY-TYPE": "Property Type",
    "SB-LABEL-PROPERTY-DATA": "Property Data",
    "SB-LABEL-PROPERTY-DISABLE-INPUT": "入力不可設定",
    "SB-LABEL-PROPERTY-SET-DEFAULT-VALUE": "デフォルト値の設定",
    "SB-LABEL-PROPERTY-SET-ABOVE-UNIT": "項目の上に設定する単位",
    "SB-LABEL-PROPERTY-SET-BELOW-UNIT": "項目の下に設定する単位",
    "SB-LABEL-PROPERTY-SET-LEFT-UNIT": "項目の左に設定する単位",
    "SB-LABEL-PROPERTY-SET-RIGHT-UNIT": "項目の右に設定する単位",
    "SB-LABEL-PROPERTY-EXCLUDE": "指定したVisitのみ除外する",
    "SB-LABEL-PROPERTY-SCRNUM": "スクリーニング番号を入れる",
    "SB-LABEL-PROPERTY-ENRNUM": "Enroll 番号を入れる",
    "SB-LABEL-PROPERTY-RANDOMIZE-CODE": "Randomize Keyのコード値を設定する",
    "SB-LABEL-PROPERTY-GENERATE-ARM": "群情報を格納する",
    "SB-LABEL-PROPERTY-GENERATE-IP-NO": "IP No.を格納する",
    "SB-LABEL-PROPERTY-GENERATE-STRATIFICATION-GROUP-NO":
        "Stratification Group No.を設定する",
    "SB-LABEL-PROPERTY-HIDE-ITEM": "隠し項目とする",
    "SB-LABEL-PROPERTY-DATA-TEXT": "テキスト",
    "SB-LABEL-PROPERTY-DATA-VISIT-ID": "VISIT ID",
    "SB-LABEL-PROPERTY-DATA-NUMBER": "数字",
    "SB-LABEL-PROPERTY-DATA-YES-NO": "Y/N",
    "SB-LABEL-PROPERTY-REGISTRATION-DATE": "登録日付",
    "SB-LABEL-PROPERTY-ACTIVATE-EPRO": "ePROを有効にする",
    "SB-LABEL-PROPERTY-GET-DATA-FROM-DEVICE": "デバイスからデータ取得",

    // Codelist
    "SB-LABEL-CODELIST": "CODE LIST",
    "SB-LABEL-CODELIST-VALUE": "Code List Value",
    "SB-LABEL-CODELIST-NAME": "名称",
    "SB-LABEL-CODELIST-UI-VALUE": "UI Value",
    "SB-LABEL-CODELIST-DB-VALUE": "DB Value",
    "SB-LABEL-CODELIST-ITEM-COUNT": "Item Count",
    "SB-LABEL-CREATE-CODELIST": "CodeListの作成",
    "SB-LABEL-EDIT-CODELIST": "CodeListの更新",
    "SB-LABEL-CODELIST-HIDE-LABEL": "ラベル非表示",
    "SB-LABEL-CODELIST-EMPTY": "EMPTY",
    "SB-LABEL-CODELIST-NOT-SELECTED": "[NotSelected]",
    "SB-LABEL-CODELIST-SETTING": "Code List設定",

    // ECS
    "SB-LABEL-ECS-LIST": "ECS一覧",
    "SB-LABEL-ECS-LOGIC-ID": "Logic ID",
    "SB-LABEL-ECS-TYPE": "Type",
    "SB-LABEL-ECS-MESSAGE": "Message",
    "SB-LABEL-ECS-FORCE-SAVE": "Force Save",
    "SB-LABEL-ECS-COPY": "コピー",
    "SB-LABEL-ECS-LINE": "Line",
    "SB-LABEL-ECS-CHECK-LOGIC": "Check Logic",
    "SB-LABEL-ECS-TO": "To",
    "SB-LABEL-ECS-SUBJECT": "Subject",
    "SB-LABEL-ECS-BODY": "Body",
    "SB-LABEL-CREATE-ECS": "ECSの新規作成",
    "SB-LABEL-EDIT-ECS": "ECSの更新",

    // Version
    "SB-LABEL-CRF-VERSION": "CRFバージョン",
    "SB-LABEL-ORIGINAL-CRF-VERSION": "元のCRFバージョン",
    "SB-LABEL-CLONE-CRF": "CRF複製",
    "SB-LABEL-ECS-VERSION": "ECSバージョン",
    "SB-LABEL-ORIGINAL-ECS-VERSION": "元のECSバージョン",
    "SB-LABEL-CLONE-ECS": "ECS複製",
    "SB-LABEL-CREATED-USER": "作成者",
    "SB-LABEL-CREATED-TIME": "作成日時",
    "SB-LABEL-CREATE-VERSION": "バージョンの作成",

    // User
    "SB-LABEL-ADMIN": "管理",
    "SB-LABEL-PRIVILEGE": "Privilege",
    "SB-LABEL-START-DATE": "Start Date",
    "SB-LABEL-END-DATE": "End Date",
    "SB-LABEL-USER": "USER",
    "SB-LABEL-USER-01": "ユーザー",
    "SB-LABEL-ADD-USER": "ユーザ追加",

    // Release
    "SB-LABEL-RELEASE": "RELEASE",
    "SB-LABEL-RELEASE-ID": "Release ID",
    "SB-LABEL-RELEASE-NAME": "Release Name",
    "SB-LABEL-RELEASE-DATE": "Release Date",

    // Clone
    "SB-LABEL-STUDY-ID": "Study ID",
    "SB-LABEL-STUDY-CLONE": "STUDY CLONE",
    "SB-LABEL-STUDY-CLONE-MESSAGE": "メッセージ",

    // IWRS
    "CM-LABEL-IWRS": "IWRS",
    "SB-LABEL-IWRS-TYPE": "TYPE",
    "SB-LABEL-IWRS-STRATIFICATION-TYPE": "割付の種類",
    "SB-LABEL-IWRS-BLIND": "盲検化",
    "SB-LABEL-IWRS-UNBLIND-SETTING": "エマージェンシーキーオープンの設定",
    "SB-LABEL-IWRS-UNBLIND-TYPE": "エマージェンシーキーオープンの種類",
    "SB-LABEL-IWRS-IP-MANAGEMENT": "IP管理",
    "CM-LABEL-IWRS-ARM": "ARM",
    "SB-LABEL-IWRS-ARM": "割付群",
    "SB-LABEL-IWRS-STATIC-STRATIFICATION": "STATIC STRATIFICATION",
    "SB-LABEL-IWRS-STRATIFICATION": "Stratification",
    "SB-LABEL-IWRS-STRATIFICATION-INFOR": "Stratification Information",
    "SB-LABEL-IWRS-STRATIFICATION-GROUP-NO": "Stratification group number",
    "SB-LABEL-IWRS-STRATIFICATION-NO": "Stratification number",
    "SB-LABEL-IWRS-STRATIFICATION-GROUP": "Stratification Group",
    "SB-LABEL-IWRS-DYNAMIC-STRATIFICATION": "DYNAMIC STRATIFICATION",

    // Super Admin - USER
    "SB-LABEL-USER-MANAGEMENT": "ユーザ一覧",
    "SB-LABEL-USER-REGISTRATION": "ユーザー登録",
    "SB-LABEL-USER-MODIFICATION": "ユーザー更新",
    "SB-LABEL-LOGIN-ID": "ログインID",

    // Super Admin - Notification
    "SB-LABEL-NOTIFICATION": "保守のお知らせ",
    "SB-LABEL-CREATE-NOTIFICATION": "保守の作成",
    "SB-LABEL-EDIT-NOTIFICATION": "保守の更新",
    "SB-LABEL-NOTIFICATION-NAME": "保守名",
    "SB-LABEL-NOTIFICATION-START-TIME": "開始日",
    "SB-LABEL-NOTIFICATION-END-TIME": "終了日",
    "SB-LABEL-NOTIFICATION-CONTENT": "メッセージ",
    "SB-LABEL-NOTIFICATION-SEARCH-BY-DATE": "日付で検索",
    "SB-LABEL-NOTIFICATION-TIMEZONE": "標準時間帯",
    "SB-LABEL-NOTIFICATION-INFORMATION": "情報",

    // Placeholder
    "CM-PLACEHOLDER-LOGIN-REQUIRED-INPUT": "{item}を入力してください",
    "CM-PLACEHOLDER-REQUIRED-INPUT": "{item}を入力してください",
    "SB-PLACEHOLDER-SCRNUM": "Screening Number",
    "SB-PLACEHOLDER-ENRNUM": "Enrollment Number",
    "SB-PLACEHOLDER-CONTRACT-CASE-LEVEL": "Limit",
    "SB-PLACEHOLDER-AUTOMATIC-LOGOUT-TIME": "Automatic logout time",
    "SB-PLACEHOLDER-IMPORT-PDF-FILE": "PDFファイルを選択してください",
    "SB-PLACEHOLDER-ID": "ID",
    "SB-PLACEHOLDER-CODE": "コード",
    "SB-PLACEHOLDER-LABEL": "ラベル",
    "SB-PLACEHOLDER-PROPERTY-DATA": "Property Data",
    "SB-PLACEHOLDER-STRATIFICATION-GROUP-NO": "Stratification group number",
    "SB-PLACEHOLDER-STRATIFICATION-NO": "Stratification number",
    "CM-PLACEHOLDER-IMPORT-EXCEL-FILE": "ファイルを選択してください",
    "CM-PLACEHOLDER-IMPORT-FILE": "{item}ファイルを選択してください",
    "SB-PLACEHOLDER-STUDY-NAME": "試験名",
    "SB-PLACEHOLDER-PROTOCOL": "プロトコルNo.",
    "SB-PLACEHOLDER-ALIAS": "Alias",

    // Dropdown
    "CM-DROPDOWN-LANGUAGE": "日本語",
    "CM-DROPDOWN-LANGUAGE-JAPANESE": "日本語",
    "CM-DROPDOWN-LANGUAGE-ENGLISH": "English",
    "SB-DROPDOWN-NO-RESTRICTION": "No restriction",
    "CM-DROPDOWN-ALL": "すべて",
    "SB-DROPDOWN-ENABLE-STATUS": "有効",
    "SB-DROPDOWN-DISABLE-STATUS": "無効",
    "SB-DROPDOWN-CATEGORY-CRF": "CRF",
    "SB-DROPDOWN-CATEGORY-FREEZE": "FREEZE",
    "SB-DROPDOWN-CATEGORY-SDV": "SDV",
    "SB-DROPDOWN-CATEGORY-REVIEW": "REVIEW",
    "SB-DROPDOWN-CATEGORY-LOCK": "LOCK",
    "SB-DROPDOWN-CATEGORY-DB-LOCK": "DB-LOCK",
    "SB-DROPDOWN-CATEGORY-SIGNATURE": "SIGNATURE",
    "SB-DROPDOWN-CATEGORY-ECS-RE": "ECS-RE",
    "SB-DROPDOWN-CATEGORY-CASE": "CASE",
    "SB-DROPDOWN-CATEGORY-PAGE": "PAGE",
    "SB-DROPDOWN-CATEGORY-ADMIN": "ADMIN",
    "SB-DROPDOWN-CATEGORY-QUERY": "QUERY",
    "SB-DROPDOWN-CATEGORY-COMMENT": "COMMENT",
    "SB-DROPDOWN-CATEGORY-DATASET": "DATASET",
    "SB-DROPDOWN-CATEGORY-ASSIGNMENT": "ASSIGNMENT",
    "SB-DROPDOWN-CATEGORY-USER-REPORT": "USER-REPORT",
    "SB-DROPDOWN-CATEGORY-DELIVERY": "DELIVERY",
    "SB-DROPDOWN-CATEGORY-LIST-UPLOAD": "LIST-UPLOAD",
    "SB-DROPDOWN-CATEGORY-BLIND": "BLIND",
    "SB-DROPDOWN-CATEGORY-DATA-IMPORT": "DATA-IMPORT",
    "SB-DROPDOWN-CATEGORY-ECONSENT": "ECONSENT",
    "SB-DROPDOWN-CATEGORY-E-TRAINING": "E-TRAINING",
    "SB-DROPDOWN-CRF-GROUP-FOR-EDC": "CrfGroup for EDC",
    "SB-DROPDOWN-CRF-GROUP-FOR-EPRO": "CrfGroup for ePRO",
    "SB-DROPDOWN-CRF-GROUP-FOR-EPRO-AND-EDC": "CrfGroup for ePRO & EDC",
    "SB-DROPDOWN-PAGE-NORMAL-TYPE": "Normal Page",
    "SB-DROPDOWN-PAGE-REGISTRATION-TYPE": "Registration Page",
    "SB-DROPDOWN-BLOCK-NORMAL-TYPE": "Normal",
    "SB-DROPDOWN-BLOCK-FIXEDLINE-TYPE": "Fixed Line",
    "SB-DROPDOWN-BLOCK-LOGLINE-TYPE": "Log Line",
    "SB-DROPDOWN-ITEM-CHECKBOX": "Checkbox",
    "SB-DROPDOWN-ITEM-DATE-YYMMDD": "Date (YYYY-MM-DD)",
    "SB-DROPDOWN-ITEM-DATE-YYMMUN": "Date (YYYY-MM-UN)",
    "SB-DROPDOWN-ITEM-DATE-YYUNUN": "Date (YYYY-UN-UN)",
    "SB-DROPDOWN-ITEM-DATE-UNUNUN": "Date (UNUN-UN-UN)",
    "SB-DROPDOWN-ITEM-DROPDOWN": "Dropdown list",
    "SB-DROPDOWN-ITEM-LABEL": "Label",
    "SB-DROPDOWN-ITEM-RADIO-VERTICAL": "Radio (Vertical)",
    "SB-DROPDOWN-ITEM-RADIO-HORIZONTAL": "Radio (Horizontal)",
    "SB-DROPDOWN-ITEM-TEXT": "Text",
    "SB-DROPDOWN-ITEM-TEXTAREA": "Text area",
    "SB-DROPDOWN-ITEM-TIME-HHMM": "Time (HH:MM)",
    "SB-DROPDOWN-ITEM-TIME-HHUN": "Time (HH:UN)",
    "SB-DROPDOWN-ITEM-TIME-UNUN": "Time (UN:UN)",
    "SB-DROPDOWN-ITEM-ROWNO": "RowNo",
    "SB-DROPDOWN-ITEM-VAS": "VAS",
    "SB-DROPDOWN-PROPERTY-AUTOFILL": "AUTOFILL",
    "SB-DROPDOWN-PROPERTY-DEFAULT": "DEFAULT",
    "SB-DROPDOWN-PROPERTY-UNIT-UPPER": "UNIT_UPPER",
    "SB-DROPDOWN-PROPERTY-UNIT-BOTTOM": "UNIT_BOTTOM",
    "SB-DROPDOWN-PROPERTY-UNIT-LEFT": "UNIT_LEFT",
    "SB-DROPDOWN-PROPERTY-UNIT-RIGHT": "UNIT_RIGHT",
    "SB-DROPDOWN-PROPERTY-EXCLUDE": "EXCLUDE",
    "SB-DROPDOWN-PROPERTY-SCRNUM": "SCRNUM",
    "SB-DROPDOWN-PROPERTY-ENRNUM": "ENRNUM",
    "SB-DROPDOWN-PROPERTY-RANDOM-KEY": "RANDOM_KEY",
    "SB-DROPDOWN-PROPERTY-ARM-GENERATE": "ARM_GENERATE",
    "SB-DROPDOWN-PROPERTY-IP-GENERATE": "IP_GENERATE",
    "SB-DROPDOWN-PROPERTY-STRATIFICATION": "STRATIFICATION",
    "SB-DROPDOWN-PROPERTY-HIDDEN": "HIDDEN",
    "SB-DROPDOWN-PROPERTY-CONSENT-DATE": "CONSENT DATE",
    "SB-DROPDOWN-PROPERTY-EPRO-TRIGGER": "ePRO TRIGGER",
    "SB-DROPDOWN-PROPERTY-GET-DEVICE-DATE": "GET_DEVICE_DATA",
    "SB-DROPDOWN-SYSTEM-QUERY": "System Query",
    "SB-DROPDOWN-BLIND": "Blind",
    "SB-DROPDOWN-LINK-FORCED": "Link Forced",
    "SB-DROPDOWN-ALERT-MAIL": "Alert Email",
    "SB-DROPDOWN-BLIND-NOT-COMMIT": "Blind (Not Commit)",
    "SB-DROPDOWN-EPRO-SCHEDULE-SAME-DAY": "the same day as",
    "SB-DROPDOWN-EPRO-SCHEDULE-NEXT-DAY": "the next day after",
    "SB-DROPDOWN-EPRO-SCHEDULE-AFTER-NUMBER-OF-DAY": "number of days after",
    "CM-DROPDOWN-SECURITY-QUESTION-MOVIE": "一番記憶に残る映画",
    "CM-DROPDOWN-SECURITY-QUESTION-BOOK": "一番記録に残る本",
    "CM-DROPDOWN-SECURITY-QUESTION-COLOR": "一番好きな色",
    "CM-DROPDOWN-SECURITY-QUESTION-FOOD": "一番好きな食べ物",

    // Checkbox
    "CM-CHECKBOX-SELECT-ALL": "全て選択",
    "CM-CHECKBOX-EMPTY": "値なし",
    "SB-CHECKBOX-DISPLAY-DELETED-RECORD": "削除されたものを表示",
    "SB-CHECKBOX-DISPLAY-DELETED-CRF": "削除されたCRFを表示",
    "SB-CHECKBOX-CDMS": "CDMS",
    "SB-CHECKBOX-IWRS": "IWRS",
    "SB-CHECKBOX-PRO": "PRO",
    "CM-CHECKBOX-ECONSENT": "eConsent",
    "SB-CHECKBOX-UPGRADE-VERSION": "バージョンアップ",
    "SB-CHECKBOX-CLONE-VERSION": "複製",
    "CM-CHECKBOX-SYSTEM-NOTIFICATION":
        "今後、このダイアログボックスを表示しない",
    "SB-CHECKBOX-EPRO-EMAIL-NOTIFICATION": "Email",
    "SB-CHECKBOX-EPRO-PUSH-NOTIFICATION": "Push",

    // Radio
    "SB-RADIO-YES-01": "YES",
    "SB-RADIO-NO-01": "NO",
    "SB-RADIO-YES-02": "有り",
    "SB-RADIO-NO-02": "無し",
    "SB-RADIO-AUTO-INPUT": "Auto Input",
    "SB-RADIO-USER-INPUT": "User Input",
    "CM-RADIO-NO-LIMITATION": "制限なし",
    "CM-RADIO-STUDY": "試験",
    "CM-RADIO-SITE": "施設",
    "SB-RADIO-DATA-TYPE-CHARACTER": "文字",
    "SB-RADIO-DATA-TYPE-NUMBER": "数字",
    "SB-RADIO-READ-PERMISSION": "Read",
    "SB-RADIO-WRITE-PERMISSION": "Write",
    "SB-RADIO-STATIC-STRATIFICATION": "静的割付",
    "SB-RADIO-DYNAMIC-STRATIFICATION": "動的割付",
    "SB-RADIO-YES-03": "あり",
    "SB-RADIO-NO-03": "なし",
    "SB-RADIO-IMMEDIATE-UBLIND": "即時開鍵",
    "SB-RADIO-UNBLIND-REQUEST": "申請/承認",
    "SB-RADIO-ARM": "群",
    "SB-RADIO-ARM-AND-IP-NO": "群+IP No.",

    // Hyperlink
    "CM-HYPERLINK-FORGOT-ID": "ユーザーIDを忘れた場合",
    "CM-HYPERLINK-FORGOT-PWD": "パスワードを忘れた場合",
    "SB-HYPERLINK-MORE": "More",
    "MSG-CM-ALERT-CHANGED-DATA": "入力したデータが破棄されます。\nよろしいでしょうか。",
};

export default JP_ITEM_MESSAGE_CODE;
