const JP_MESSAGES_MESSAGE_CODE = {
    null: "",
    "": "",

    "MSG-CM-WRONG-ID-PWD": "ユーザーIDまたはパスワードが異なります。",
    "MSG-CM-WRONG-PWD-5-TIMES":
        "パスワードを5回間違えたため、アカウントがロックされました。「パスワードを忘れた場合」よりパスワードを初期化するかシステム管理者へお問い合わせください。",
    "MSG-CM-WRONG-PASSWORD":
        "パスワードが正しくありません。（ログインに{x}回失敗しました。）",
    "MSG-CM-NON-EXISTED-EMAIL":
        "入力されたメールアドレスは登録されていません。",
    "MSG-CM-WRONG-SECRET-QUESTION": "初回に設定した秘密の質問と異なります。",
    "MSG-CM-SENT-NEW-PWD-VIA-EMAIL": "新しいパスワードをメールで送信しました。",
    "MSG-CM-SENT-ID-VIA-EMAIL": "ユーザーIDをメールで送信しました。",
    "MSG-CM-INVALID-PASSWORD":
        "パスワードは半角で8文字以上（英字と数字の組み合わせ）を入力してください。",
    "MSG-CM-NOT-MATCH-PASSWORD": "パスワードが一致しません。",
    "MSG-CM-MATCH-PREVIOUS-PASSWORD":
        "以前に登録されたパスワードと同様のパスワードが設定されています。",
    "MSG-CM-SETTING-PWD-SUCCESSFULLY": "パスワード設定が完了しました。",
    "MSG-STU-CLOSE-STUDY": "試験をクローズしてもよろしいですか？",
    "MSG-STU-OPEN-STUDY": "試験をオープンしてもよろしいですか？",
    "MSG-ETRA-DUPLICATED-FILE-NAME":
        "すでに登録されている資料と同一名称の資料はアップロードできません。",
    "MSG-ETRA-INVALID-FILE-NAME":
        'ファイル名称は記号{\\/:*?"<>+|,}以外を設定してください。',
    "MSG-CM-DELETE-PARENT-ITEM":
        "{parent_item}を削除したら全ての{child_item}も削除されます。\nよろしいですか。",
    "MSG-CM-RESTORE-PARENT-ITEM":
        "{parent_item}を回復したら全ての{child_item}も回復されます。\nよろしいですか。",
    "MSG-CM-DELETE-ALL-CHILD-ITEM":
        "全ての{child_item}を削除したら、{parent_item}も削除されます。\nよろしいですか。",
    "MSG-SCHE-NOT-SET-SCHEDULE-YET": "スケジュールは設定されていません。",
    "MSG-ENT-SCHE-CYCLE-ID-NOT-EXIST":
        "{sheet_name} SheetのRow {n}:　Cycle「cycle_id」が存在しません。",
    "MSG-ENT-SCHE-CYCLE-ID-VISIT-ID-NOT-EXIST":
        "{sheet_name} SheetのRow {n}:「cycle_id | visit_id」が存在しません。",
    "MSG-ENT-SCHE-CYCLE-ID-VISIT-ID-PAGE-ID-NOT-EXIST":
        "{she{sheet_name} SheetのRow {n}:「cycle_id |visit_id | page_id」が存在しません。",
    "MSG-ENT-SCHE-PAGE-ID-NOT-ASSIGN-CYCLE-ID-VISIT-ID":
        "{sheet_name} SheetのRow {n}: 「page_id」 が 「cycle_id |visit_id」に存在しません.",
    "MSG-PAG-SET-NORMAL-TYPE-FOR-PAGE-WITH-SCRNUM-ITEM":
        "このページにScreening numberがすでに設定されているため、ページの種別を変更できません。",
    "MSG-PAG-SET-NORMAL-TYPE-FOR-PAGE-WITH-CONSENT-DATE-ITEM":
        "CONSENT_DATE設定済のページはページタイプが変更できません。",
    "MSG-BLO-SET-TABLE-TYPE-FOR-BLOCK-WITH-SCRNUM-ITEM":
        "このブロックにScreening numberがすでに設定されているため、ブロックの種別を変更できません。",
    "MSG-BLO-SET-TABLE-TYPE-FOR-BLOCK-WITH-ENRNUM-ITEM":
        "このブロックにEnrollment numberがすでに設定されているため、ブロックの種別を変更できません。",
    "MSG-BLO-SET-TABLE-TYPE-FOR-BLOCK-WITH-STRATIFICATION-ITEM":
        "このブロックにSTRATIFICATIONがすでに設定されているため、ブロックの種別を変更できません。",
    "MSG-BLO-SET-TABLE-TYPE-FOR-BLOCK-WITH-EPRO-TRIGGER-ITEM":
        "このブロックにePRO_TRIGGERがすでに設定されているため、ブロックの種別を変更できません。",
    "MSG-ITE-DATA-LENGTH-LESS-THAN-DB-VALUE":
        "CODE LISTに設定されているコード値の桁数より小さい値が設定されています。",
    "MSG-ITE-CONFIRM-DELETE-DATA-DUE-TO-LAYOUT-CHANGE":
        "レイアウトの変更によりCDMSの入力データが削除される可能性があります。本当に変更しますか？",
    "MSG-ITE-DIFFERENT-DATA-TYPE-BETWEEN-CODELIST-AND-ITEM":
        "CODE LISTに設定されているコード値が項目のデータタイプに交換できません。",
    "MSG-ITE-CHANGE-INVALID-LAYOUT-FOR-ENRNUM-ITEM":
        "この項目にENROLLMENT NUMBERがすでに設定されているため、項目のレイアウトを変更できません。",
    "MSG-ITE-DUPLICATED-ITEM-ID-IN-THE-SAME-DOMAIN":
        "同一DOMAINに同一のIDを設定することはできません。",
    "MSG-ITE-CHANGE-INVALID-LAYOUT-FOR-SCRNUM-ITEM":
        "この項目にSCREENING NUMBERがすでに設定されているため、項目のレイアウトを変更できません。",
    "MSG-ITE-CHANGE-INVALID-LAYOUT-FOR-ARM-GENERATE-ITEM":
        "この項目にARM_GENERATEがすでに設定されているため、TEXTとTEXT AREA以外のレイアウトに変更できません。",
    "MSG-ITE-CHANGE-INVALID-LAYOUT-FOR-IP-GENERATE-ITEM":
        "この項目にIP_GENERATEがすでに設定されているため、TEXTとTEXT AREA以外のレイアウトに変更できません。",
    "MSG-ITE-CHANGE-INVALID-LAYOUT-FOR-RANDOM-KEY-ITEM":
        "この項目にRANDOM_KEYがすでに設定されているため、LABELとROW NOのレイアウトに変更できません。",
    "MSG-ITE-CHANGE-INVALID-LAYOUT-FOR-STRATIFICATION-ITEM":
        "この項目にSTRATIFICATIONがすでに設定されているため、LABEL、DATEまたTIMEのレイアウトに変更できません。",
    "MSG-ITE-CHANGE-INVALID-LAYOUT-FOR-CONSENT-DATE-ITEM":
        "この項目にCONSENT DATEがすでに設定されているため、DATE以外のレイアウトに変更できません。",
    "MSG-ITE-CHANGE-INVALID-LAYOUT-FOR-DATE-FUTURE-ITEM":
        "この項目にDATE_FUTUREがすでに設定されているため、DATE以外のレイアウトに変更できません。",
    "MSG-ITE-VAS-MAX-VALUE-LESS-THAN-MIN-VALUE":
        "最大値を最小値より大きい値で入力してください。",
    "MSG-ITE-CHANGE-INVALID-LAYOUT-FOR-GET-DEVICE-DATA-ITEM":
        "この項目にGET_DEVICE_DATAがすでに設定されているため、TEXTとTEXT AREA以外のレイアウトに変更できません。",
    "MSG-ITE-CHANGE-INVALID-LAYOUT-FOR-EPRO-TRIGGER-ITEM":
        "この項目にePRO TRIGGERがすでに設定されているため、DATE以外のレイアウトに変更できません。",
    "MSG-CM-IMPORT-MANY-SHEET-MIN-VALUE-GREATER-THAN-MAX-VALUE":
        "{sheet_name}シートに{n}行目：最大値が最小値より大きい数値を入力します。",
    "MSG-PRO-SET-INVALID-PROPERTY-FOR-NORMAL-PAGE-ITEM":
        "このPropertyはNormal Pageの項目に設定できません。",
    "MSG-PRO-SET-EXCLUDE-AND-SCRNUM FOR-SAME-ITEM":
        "EXCLUDEとSCRNUMは同じ項目に設定できません。",
    "MSG-PRO-SET-SCRNUM-FOR-NON-NORMAL-BLOCK-ITEM":
        "SCRNUMはNormal Block以外の項目に設定できません。",
    "MSG-PRO-SET-ENRNUM-AND-SCRNUM FOR-SAME-ITEM":
        "ENRNUMとSCRNUMは同じ項目に設定できません。",
    "MSG-PRO-SET-MULTIPLE-ENRNUM-ITEM-FOR-A-PAGE":
        "同じページに複数ENRNUMが設定できません。",
    "MSG-PRO-SET-ENRNUM-FOR-NON-TEXT-ITEM":
        "ENRNUMはTEXT以外の項目に設定できません。",
    "MSG-PRO-SET-ENRNUM-FOR-NON-NORMAL-BLOCK-ITEM":
        "ENRNUMはNormal Block以外の項目に設定できません。",
    "MSG-ENT-PRO-MORE-THAN-ONE-REGISTRATION-PAGE":
        "Registrationが二つ以上のページで選択されています。",
    "MSG-PRO-SET-MULTIPLE-SCRNUM-ITEM-FOR-A-PAGE":
        "同じページに複数SCRNUMが設定できません。",
    "MSG-PRO-SET-SCRNUM-FOR-NON-TEXT-ITEM":
        "SCRNUMはTEXT以外の項目に設定できません。",
    "MSG-PRO-SET-INVALID-PROPERTY-FOR-LABEL-AND-ROWNO-ITEM":
        "このPropertyはLABELとROW NOの項目に設定できません。",
    "MSG-PRO-SET-ARM-IP-GENERATE-AND-GET-DEVICE-DATA-FOR-ITEM-WITH-NON-TEXT-NON-TEXTAREA-LAYOUT":
        "このPropertyはTEXTとTEXT AREA以外の項目に設定できません。",
    "MSG-PRO-SET-STRATIFICATION-FOR-ITEM-WITH-LABEL-DATE-TIME-LAYOUT":
        "このPropertyはLABEL、DATEまたTIMEの項目に設定できません。",
    "MSG-PRO-SET-MULTIPLE-RANDOM-KEY-ITEM-FOR-A-STUDY":
        "RANDOM_KEYは二つ以上の項目に設定できません。",
    "MSG-PRO-SET-STRATIFICATION-GROUP-NO-FOR-MULTIPLE-ITEM-IN-A-STUDY":
        "一つのStratificationグループNo.は二つ以上の項目に設定できません。",
    "MSG-PRO-SET-MULTIPLE-STRATIFICATION-FOR-AN-ITEM":
        "複数のStratificationグループNo.は同じの項目に設定できません。",
    "MSG-PRO-SET-MULTIPLE-RANDOM-KEY-FOR-AN-ITEM":
        "同じ項目に複数のRANDOM_KEYを設定できません。",
    "MSG-PRO-SET-ARM-IP-GENERATE-FOR-AN-ITEM":
        "ARM_GENERATEとIP_GENERATEは同じ項目に設定できません。",
    "MSG-PRO-SET-HIDDEN-AND-SCRNUM-FOR-AN-ITEM":
        "HIDDENとSCRNUMは同じ項目に設定できません。",
    "MSG-PRO-SET-HIDDEN-AND-ENRNUM-FOR-AN-ITEM":
        "HIDDENとENRNUMは同じ項目に設定できません。",
    "MSG-PRO-SET-STRATIFICATION-FOR-NON-NORMAL-BLOCK-ITEM":
        "STRATIFICATIONはNormal Block以外の項目に設定できません。",
    "MSG-PRO-SET-CONSENT-DATE-FOR-NON-DATE-ITEM":
        "CONSENT DATEはDATE以外の項目に設定できません。",
    "MSG-PRO-SET-EPRO-TRIGGER-FOR-NON-FULL-DATE-ITEM":
        "ePRO TRIGGERはDate (YYYY/MM/DD)以外の項目に設定できません。",
    "MSG-PRO-SET-EPRO-TRIGGER-FOR-NON-NORMAL-BLOCK-ITEM":
        "ePRO TRIGGERはNormal Block以外の項目に設定できません。",
    "MSG-PRO-SET-MUTIPLE-EPRO-TRIGGER-ITEM-FOR-A-PAGE":
        "同じページに複数ePRO TRIGGERが設定できません。",
    "MSG-PRO-SET-DATE-FUTURE-FOR-NON-DATE-ITEM":
        "DATE FUTUREはDATE以外の項目に設定できません。",
    "MSG-COD-WARNING-CODELIST-IN-USE-FOR-AN-ITEM-NOT-BE-DELETED":
        "このCODE LISTはItemに設定されているため削除できません。",
    "MSG-COD-DB-VALUE-GREATER-THAN-DATA-LENGTH":
        "項目に設定されているデータ長さより大きい値が設定されています。",
    "MSG-COD-INVALID-CODELIST-VALUE-FOR-NUMERIC-ITEM":
        "数値データタイプの項目に設定されているCodeListです。",
    "MSG-ECS-EMPTY-TARGET":
        "Visit ID、Page ID、Block ID、Item ID、Lineのうち、少なくとも一つを入力してください。",
    "MSG-CM-IMPORT-ECS-EMPTY-TARGET":
        "{n}行目：Visit ID、Page ID、Block ID、Item ID、Lineのうち、少なくとも一つを入力してください。",
    "MSG-VER-DUPLICATED-VERSION-PAIR":
        "入力されたCRFバージョンとECSバージョンがすでに連動されています。",
    "MSG-CM-END-DATE-GREATER-THAN-START-DATE":
        "開始日と終了日の日付が逆転しております。",
    "MSG-ADM-RELEASE-SUCCESS": "試験のリリースが完了しました。",
    "MSG-ADM-RELEASE-CONFIRMATION": "本当にリリースしますか？",
    "MSG-ADM-CLONE-SUCCESS": "{newStudyId}のクローンが完了しました。",
    "MSG-ADM-WARNING-NOT-DELETE-USER-ASSIGNED-TO-STUDY":
        "このユーザーは試験に設定されているため、削除できません。",
    "MSG-ARM-DELETE-CONFIRMATION":
        "ARMを削除すると全ての割付結果が削除されます。\nよろしいですか。",
    "MSG-CM-RESET-PWD-CONFIRMATION":
        "パスワードを初期化します。\nよろしいですか。",
    "MSG-CM-RESET-PASSWORD-SUCCESSFULLY":
        "パスワードを初期化しました。\n新しいパスワードをメールに送信しました。",
    "MSG-CM-IMPORT-ONE-SHEET-INVALID-VALUE":
        "{n}行目：{item}に入力したデータが正しくありません。",
    "MSG-CM-IMPORT-ONE-SHEET-OVER-MAXLENGTH":
        "{n}行目：{item}の長さが{max_length}以下入力してください。",
    "MSG-CM-IMPORT-ONE-SHEET-EMPTY-VALUE": "{n}行目：{item}が必須です。",
    "MSG-CM-IMPORT-ONE-SHEET-DUPLICATED-VALUE":
        "{n}行目：{item}がすでに使用されています。",
    "MSG-CM-IMPORT-ONE-SHEET-NON-EXISTED-VERSION":
        "{n}行目：存在しないバージョンのデータが存在します。",
    "MSG-CM-IMPORT-MANY-SHEET-EMPTY-VALUE":
        "{sheet_name}シートに{n}行目：{item}が必須です。",
    "MSG-CM-IMPORT-MANY-SHEET-INVALID-VALUE":
        "{sheet_name}シートに{n}行目：{item}に入力したデータが正しくありません。",
    "MSG-CM-IMPORT-MANY-SHEET-OVER-MAXLENGTH":
        "{sheet_name}シートに{n}行目：{item}の長さが{max_length}以下入力してください。",
    "MSG-CM-IMPORT-MANY-SHEET-NON-EXISTED-VALUE":
        "{sheet_name}シートに{n}行目：入力した{item}が存在しません。",
    "MSG-CM-IMPORT-MANY-SHEET-DUPLICATED-VALUE":
        "{sheet_name}シートに{n}行目：入力データが重複されました。",
    "MSG-CM-IMPORT-MANY-SHEET-DATA-LENGTH-LESS-THAN-DB-VALUE":
        "{sheet_name}シートに{n}行目：Code Listに設定されているコード値の桁数より小さい値が設定されています。",
    "MSG-CM-IMPORT-MANY-SHEET-NON-EXISTED-GROUP-ID":
        "{sheet_name}シートに{n}行目：{item}が存在しません。",
    "MSG-CM-IMPORT-MANY-SHEET-NON-EXISTED-BLOCK-ID":
        "{sheet_name}シートに{n}行目：{item}の{block_id}が存在しません。",
    "MSG-CM-IMPORT-MANY-SHEET-NON-EXISTED-CYCLE-ID":
        "{sheet_name} SheetのRow {n}:　Cycle「cycle_id」が存在しません。",
    "MSG-CM-IMPORT-MANY-SHEET-NON-EXISTED-CYCLE-VISIT-ID":
        "{sheet_name} SheetのRow {n}:「cycle_id | visit_id」が存在しません。",
    "MSG-CM-IMPORT-MANY-SHEET-NON-EXISTED-CYCLE-VISIT-PAGE-ID":
        "{she{sheet_name} SheetのRow {n}:「cycle_id |visit_id | page_id」が存在しません。",
    "MSG-CM-IMPORT-MANY-SHEET-NON-EXISTED-PAGE-ID":
        "{sheet_name} SheetのRow {n}: 「page_id」 が 「cycle_id |visit_id」に存在しません.",
    "MSG-CM-IMPORT-SUCCESSFULLY": "取り込みが完了しました。",
    "MSG-CM-IMPORT-NOT-SELECT-FILE": "{item}を選択してください。",
    "MSG-CM-DUPLICATED-DATA": "入力された{item}がすでに使用されています。",
    "MSG-CM-NO-DATA": "データがありません",
    "MSG-CM-INVALID-DATA": "入力したデータが正しくありません。",
    "MSG-CM-REQUIRED-DATA-INPUT": "{item}を入力してください。",
    "MSG-CM-SERVER-ERROR":
        "エラーが発生しました。<br />管理者に連絡してください。",
    "MSG-CM-SAVE-DATA-SUCCESSFULLY": "{type}が保存されました。",
    "MSG-CM-LOST-NETWORK-CONNECTION":
        "インターネットの接続を確認してください。",
    "MSG-CM-API-TIMEOUT": "タイムアウトが発生しました。",
    "MSG-CM-IMPORT-NOT-SELECT-EXCEL-FILE": "Excelファイルを選択してください。",
    "MSG-IMPORT-NOT-SELECT-PDF-FILE": "PDFファイルを選択してください。",
    "MSG-CM-IMPORT-NON-EXISTED-FILE": "選択したファイルが存在しません。",
    "MSG-CM-IMPORT-EXCEED-FILE-SIZE":
        "ファイルのサイズが大きすぎます。{xx}MB以下のファイルを選択してください。",
    "MSG-CM-IMPORT-BLANK-FILE":
        "ファイルが空白です。他のファイルを選択してください",
    "MSG-ETRA-FILE-NAME-EXCEED-MAXLENGTH":
        "ファイル名称は80文字以下を設定してください。",
    "MSG-CM-ALERT-CHANGED-DATA":
        "入力したデータが破棄されます。\nよろしいでしょうか。",
    "MSG-CM-DELETE-CONFIRMATION": "{item}を削除します。\nよろしいですか。",
    "MSG-CM-RESTORE-CONFIRMATION": "{item}を回復します。\nよろしいですか。",
    "MSG-CM-ALERT-NOT-SAVE-DATA":
        "変更されたデータがないため、保存されません。",
    "MSG-CM-REQUEST-REFRESH-PAGE":
        "データが変更されました。\nページを更新してください。",
    "MSG-COD-REQUIRED-CODELIST-VALUE": "1つ以上のコード値を入力してください。",
    "MSG-CM-DUPLICATED-EMAIL":
        "入力したメールアドレスはすでに登録されています。",
    "MSG-CM-REQUIRED-SECURITY-ANSWER":
        "秘密の質問に対する回答を入力してください。",
    "MSG-CM-INCORRECT-SECURITY-ANSWER": "秘密の質問に対する回答が異なります。",
    "MSG-ADM-WARNING-NOT-RESTORE-USER-DELETED-BY-SUPER-ADMIN":
        "このユーザーはスーパー管理者画面で削除されたため、回復できません。",
    "MSG-CM-IMPORT-INCORRECT-FILE-FORMAT": "ファイルの形式が異なります。",
    "MSG-LOGIN-REQUIRED-DATA-INPUT": "{item}を入力してください。",
    "MSB-EDC-REQUIRED-MAX-LIMIT-PER-STUDY": "Maximum limitを入力してください。",
    "MSB-EDC-REQUIRED-MAX-LIMIT-PER-SITE": "Maximum limitを入力してください。",
};

export default JP_MESSAGES_MESSAGE_CODE;
