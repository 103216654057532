export const CONSTANTS = {
    DB_DELETE_FLAG : 1,
    DB_UNDELETE_FLAG : 0,
    DB_ITEM_LAYOUT_HORIZONTAL_CHECKBOX: 1,
    DB_ITEM_LAYOUT_DATE_YYYY_MM_DD: 2,
    DB_ITEM_LAYOUT_DATE_YYYY_MM_UN: 3,
    DB_ITEM_LAYOUT_DATE_YYYY_UN_UN: 4,
    DB_ITEM_LAYOUT_DATE_UNUN_UN_UN: 5,
    DB_ITEM_LAYOUT_DROPDOWN_LIST: 6,
    DB_ITEM_LAYOUT_LABEL: 7,
    DB_ITEM_LAYOUT_RADIO_VERTICAL: 8,
    DB_ITEM_LAYOUT_RADIO_HORIZONTAL: 9,
    DB_ITEM_LAYOUT_TEXT: 10,
    DB_ITEM_LAYOUT_TEXT_AREA: 11,
    DB_ITEM_LAYOUT_TIME_HH_MM: 12,
    DB_ITEM_LAYOUT_TIME_HH_UN: 13,
    DB_ITEM_LAYOUT_TIME_UN_UN: 14,
    DB_ITEM_LAYOUT_ROW_NO: 15,
}