import { notification } from 'antd';
import intl from "react-intl-universal";

const LoadingComponent = ({isLoading, error}) => {
    if(error){
        console.log('LoadingComponent error', error)
        notification.error({
            message: intl.get("TYPE.ERROR"),
            description: intl.get("MSG-CM-LOST-NETWORK-CONNECTION")
        });
    }
    return null;
};

export default LoadingComponent;