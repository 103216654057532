import React from 'react';
import { Spin, Icon } from 'antd';

export default function LoadingIndicator(props) {
    const antIcon = <Icon type="loading-3-quarters" style={{ fontSize: 30 }} spin />;
    if (props.isLoading){
        return (
            <div className="loading-container">
                <Spin indicator={antIcon} style = {{display: 'block', textAlign: 'center', top: "45%", marginLeft:"-10px", left:"50%", position:"fixed", zIndex:"99" }} />
                <div className="loading-blur"></div>
            </div>
        );
    }else{
        return null;
    }
}